import { User, UserManager } from "oidc-client";
import { logWarn } from "../utils/logger";
import { AuthConfig } from "../type";
import SessionServiceClient from "../sessionsvc/SessionServiceClient";
import AmplifyAuth from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import AuthHandler from "./AuthHandler";

const REDIRECT_SEARCH_PARAMETER = "redirect";

const CODE_SEARCH_PARAMETER = "code";

/**
 * Auth handler class for Cognito authentication flow.
 */
export default class CognitoAuthHandler extends AuthHandler {
  private readonly useSelfHostedLoginUI : boolean | undefined;

  constructor(userManager : UserManager,
              sessionServiceClient : SessionServiceClient,
              authConfig : AuthConfig) {
    super(userManager, sessionServiceClient, authConfig);
    this.useSelfHostedLoginUI = authConfig.useSelfHostedLoginUI;
  }

  public async signIn() : Promise<void> {
    const currentUrl = new URL(window.location.href);

    if (currentUrl.href !== this.authConfig.redirectSignIn && !currentUrl.searchParams.get(CODE_SEARCH_PARAMETER)
      && !currentUrl.searchParams.get(REDIRECT_SEARCH_PARAMETER)) {

      const urlWithRedirect = new URL(this.authConfig.redirectSignIn);
      const pathToPreserve = window.location.href.replace(window.location.origin, "");
      urlWithRedirect.searchParams.append(REDIRECT_SEARCH_PARAMETER, pathToPreserve);
      window.location.replace(urlWithRedirect.href);
      return;
    }

    const code = currentUrl.searchParams.get(CODE_SEARCH_PARAMETER);

    if (!code) {
      // sign in redirect: sign in and get the code
      try {
        const fullPath = window.location.href.replace(window.location.origin, "");
        await this.userManager.signinRedirect({state: fullPath});
      } catch (e) {
        logWarn('AuthManager.CodeSignin fail calling UserManager signinRedirect', e);
        throw e;
      }
    } else {
      let path = '';
      // sign in redirect callback: use the code to get the token
      let user: User;
      try {
        user = await this.userManager.signinRedirectCallback();
        if (user.state) {
          //we want to avoid double slashes in url
          path = this.authConfig.redirectSignIn.endsWith('/') ?  user.state.replace(/^\//, '') : user.state;
        }
      } catch (e) {
        logWarn('AuthManager_ts.CodeSigninCallback fail', e);
        // Re initialize the login if the SigninCall back fail. Avoid getting stuck at random 'code?=' page.
        window.location.assign(this.authConfig.redirectSignIn);
        throw e;
      }

      // call session service first, then redirect to application to avoid racing condition
      try {
        await this.sessionServiceClient.getCredentialsFromToken(user.id_token, null);
      } catch (e) {
        logWarn('AuthManager.CodeSignin call session service fail', e);
        throw (e);
      }

      if (!path) {
        // redirect to the app path if a custom state is saved before.
        // For example, if user directly visit portal.com/Townsend.
        // After redrect from identity provider, user should be redirected back to Townsend.
        window.history.replaceState(null, '', window.location.pathname);
      } else {
        const arrivalUrl = new URL(path, this.authConfig.redirectSignIn);
        const redirectUrl = arrivalUrl.searchParams.get(REDIRECT_SEARCH_PARAMETER);
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          window.location.replace(arrivalUrl.href);
        }
      }
    }
  }

  /**
   * Silent sign in to refresh id tokens in the background.
   *  - If self-hosted login is enabled, AmplifyAuth is used to refresh tokens
   *  - Otherwise, OIDC user manager is applied.
   *
   * As we fully migrate to new login page for regions that Cognito is available, the second path should go away.
   *
   */
  public async silentSignIn() : Promise<void> {
    if (this.useSelfHostedLoginUI === true) {
        const cognitoUser : CognitoUser = await AmplifyAuth.currentAuthenticatedUser();
        const currentSession = await AmplifyAuth.currentSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), this.handleCallbackForSessionRefresh);
    } else {
      try {
        await this.userManager.signinSilent();
        return;
      } catch (e) {
        logWarn('AuthManager', 'silent sign in fail calling UserManager signinSilent: ' + e)
        throw (e);
      }
    }
  }

  /**
   * Function to handle amplify session refresh call back.
   *
   * @param e error from the session refresh call back
   */
  public handleCallbackForSessionRefresh(e: Error): void {
    if (e) {
      logWarn('AuthManager', 'Silent sign in failed calling AmplifyAuth to refresh tokens: ' + e);
      throw(e);
    }
  }

  /**
   * Federated SignIn method used for single sign on (SSO) users.
   */
  public async federatedSignIn(providerName : string, customState: string): Promise<void> {
    await AmplifyAuth.federatedSignIn({customProvider: providerName, customState: customState});
  }

  /**
   * Sign in method used for new self-hosted login UI by calling Amplify Auth signIn method.
   *
   * @param username username as string
   * @param pwd password as string
   */
  public async landingPageSignIn(username: string, pwd: string): Promise<CognitoUser> {
    return await AmplifyAuth.signIn(username, pwd);
  }

  /**
   * Responds auth challenge required for MFA by using Amplify library.
   *
   * @param challengeResponse custom auth challenge response required for MFA (confirmation code).
   * @param cognitoUser cognito user to respond Auth challenge for.
   */
  public async respondAuthChallenge(challengeResponse: string, cognitoUser: CognitoUser): Promise<void> {
    await AmplifyAuth.sendCustomChallengeAnswer(cognitoUser, challengeResponse);
    const user = await this.userTokenManager.getUserFromStorage();
    const authenticated = user !== null;

    if (authenticated) {
      await this.sessionServiceClient.getSessionFromLocalStorage(user);
      const redirect_url = new URL(window.location.href).searchParams.get("redirect");
      if (redirect_url) {
        window.location.replace(redirect_url);
      }
    } else {
      throw new Error("Invalid challenge response is entered");
    }
  }
}
