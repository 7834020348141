import { UserManager } from "oidc-client";
import SessionServiceClient from "../sessionsvc/SessionServiceClient";
import { AuthConfig } from "../type";
import { logWarn } from "../utils/logger";
import AuthHandler from "./AuthHandler";
import { NonceManager } from "../utils/NonceManager";


/**
 * Auth handler class for Midway authentication flow.
 */
export default class MidwayAuthHandler extends AuthHandler {
  constructor(userManager : UserManager,
              sessionServiceClient : SessionServiceClient,
              authConfig : AuthConfig) {
    super(userManager, sessionServiceClient, authConfig);
  }

  public async signIn() : Promise<void> {
    if (!this.userManager) {
      return;
    }

    if (!this.signinImplicitCheckToken()) {
      const nonce : string = this.generateNonce();
      NonceManager.setNonceToStorage(nonce);

      const encryptedNonce : string = NonceManager.encryptNonce(nonce);

      try {
        await this.userManager.signinRedirect({
          extraQueryParams: {
            nonce: encryptedNonce,
          },
        });
      } catch (e) {
        logWarn('AuthManager.ImplicitSignin fail calling UserManager.signinRedirect', e);
        throw e;
      }
    }

    const user = await this.userTokenManager.getUserFromStorage();
    const nonce = NonceManager.getNonceFromStorage();

    if (!user) {
      return;
    }
    try {
      await this.sessionServiceClient.getCredentialsFromToken(user.id_token, nonce);
    } catch (e) {
      logWarn('AuthManager.ImplicitSignin call session service fail', e);
      throw (e);
    }
  }

  /**
   * Implicit flow does not have refresh endpoint, use HttpRequest sent to midway
   */
  public async silentSignIn() : Promise<void>{
    try {
      await this.refreshImplicitTokenRequest();
    } catch (e) {
      logWarn('AuthManager.refresh implicit token fail', e);
      throw (e);
    }
  }
  private async refreshImplicitTokenRequest() {
    if (!this.authConfig) {
      return;
    }
    const nonce = this.generateNonce();
    NonceManager.setNonceToStorage(nonce);

    const encryptedNonce = NonceManager.encryptNonce(nonce);
    const url = this.authConfig.implicitFlowRefreshEndpoint +
      "?redirect_uri=" + this.authConfig.redirectSignIn +
      "&client_id=" + this.authConfig.clientId +
      "&scope=openid&response_type=id_token" +
      "&nonce=" + encryptedNonce;

    await fetch(url ,{
      mode:'cors',
      method: 'GET',
      credentials: 'include',
    }).then(response =>  response.text().then(text => ({ok: response.ok, text: text})))
      .then(responseData => {
        if (responseData.ok) {
          this.userTokenManager.setUserToStorage(responseData.text, "", "", this.getTokenExpireTime());
        }
      }).catch(e => {
        logWarn("error calling midway to refresh tokens", e);
        throw e;
      });
  }

  /**
   * Checks if the JWT token(idToken || MidwayToken) is already created
   */
  private signinImplicitCheckToken() : boolean {
    const currentUrl = new URL(window.location.href);
    const implicitToken = currentUrl.searchParams.get('id_token');
    if (implicitToken) {
      this.userTokenManager.setUserToStorage(implicitToken, "", "", this.getTokenExpireTime());
      window.history.replaceState(null, '', window.location.pathname);
      return true;
    } else {
      return false;
    }
  }

  // Generate nonce: use the same format and function oidc-client is using to generate uuidv4
  // https://github.com/IdentityModel/oidc-client-js/blob/dev/src/random.js
  private generateNonce() : string {
    return (1e7.toString()+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (parseInt(c) ^ Math.random() * 16 >> parseInt(c) / 4).toString(16)
    );
  }

  private getTokenExpireTime() : number {
    // check expiretime number | undefined to pass typescript check. The default actually won't be reached.
    // Under current structure, this getTokenExpireTime() will only be called after checking configured or not
    // therefore always been called with a valid expireTime.
    // TODO: if future update changed the calling strategy, review the default expire time
    const defaultExpireTime = 600; // Default set to same as Portal refresh time
    if (!this.authConfig || !this.authConfig.tokenExpirationTime) {
      return new Date().getTime()/1000 + defaultExpireTime;
    } else {
      return new Date().getTime()/1000 + this.authConfig.tokenExpirationTime;
    }
  }

}
